import React from 'react';
import { Typography, Link } from '@material-ui/core';
import { Link as RouterLink } from 'react-router-dom';
import { EatonLinkButton } from '../button';
import { useStyles } from './styles';
import { DefaultPage } from '../default-page';

export const PureAccountCreatedView = (props: { existingUser: boolean }) => {
  const classes = useStyles();
  return (
    <DefaultPage>
      <Typography variant="h1" className={classes.createAccountHeading}>
        Account {props.existingUser ? 'Updated!' : 'Created!'}
      </Typography>
      <Typography className={classes.accountCreatedMessage}>
        Your account has been {props.existingUser ? 'updated' : 'created'}.{' '}
        <Link component={RouterLink} to="/login">
          Log in
        </Link>{' '}
        and create your first transmission spec.
      </Typography>
      {!props.existingUser && (
        <EatonLinkButton data-testid="createspec" to="/survey">
          Create Spec
        </EatonLinkButton>
      )}
    </DefaultPage>
  );
};
