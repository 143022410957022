import React, { useState } from 'react';
import { Transmission } from '../../domain/transmission';
import { PureTransmissionTable } from './pure-transmission-table';
import { EatonSnackBar, SnackBarType } from '../snack-bar';
import { useAuthenticatedRequest } from '../reusable-components/hooks/use-authenticated-request';
import { useAuthDispatch } from '../reusable-components';
import { ErrorPage } from '../../pages/error/Error';

export function ListTransmissions() {
  const { authenticatedRequest } = useAuthDispatch();

  const [showError, setShowError] = useState<boolean>(false);
  const [
    loadingTransmissions,
    transmissions,
    requeryFunction,
  ] = useAuthenticatedRequest<Transmission[]>({
    requestConfig: {
      method: 'GET',
      url: `api/transmission/`,
    },
  });

  const deleteTransmission = async (id: number) => {
    const response = await authenticatedRequest({
      url: `api/transmission/${id}`,
      method: 'DELETE',
    });
    if (response.status < 300) {
      requeryFunction();
    } else {
      setShowError(true);
    }
  };

  if (!loadingTransmissions && transmissions === undefined) {
    return <ErrorPage />;
  }

  return (
    <div>
      <EatonSnackBar
        type={SnackBarType.Error}
        message="Unknown error occured while attempting deletion."
        showSnack={showError}
        onClose={() => setShowError(false)}
      />
      <PureTransmissionTable
        transmissions={transmissions || []}
        deleteFunction={deleteTransmission}
        loading={loadingTransmissions}
      />
    </div>
  );
}
