import React, { useState } from 'react';
import { Transmission } from '../../domain/transmission';
import {
  Table,
  TableHead,
  TableRow,
  TableBody,
  Tooltip,
  IconButton,
  Typography,
  TableFooter,
  Paper,
  CircularProgress,
} from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import AddIcon from '@material-ui/icons/Add';
import { StyledTableDataRow, StyledTableCell, useStyles } from './styles';
import { cssClassName } from '../pure-survey';
import { EatonAlertDialog } from '../alert-dialog';
import { Link } from 'react-router-dom';
import { EatonLinkButton } from '../button';

type OwnProps = {
  transmissions: Transmission[];
  deleteFunction: (id: number) => Promise<void>;
  loading: boolean;
};

export function PureTransmissionTable(props: OwnProps) {
  const [selectedRow, setSelectedRow] = useState<number | undefined>(undefined);
  const [openDialog, setOpenDialog] = useState<boolean>(false);

  const onAgreeDialog = async () => {
    if (selectedRow) {
      await props.deleteFunction(selectedRow);
      setSelectedRow(undefined);
    }
    setOpenDialog(false);
  };

  const classes = useStyles();

  const getEmptyTable = () => {
    return (
      <div style={{ padding: '50px', textAlign: 'center' }}>
        <Typography variant="h6">No Transmissions Found</Typography>
        <EatonLinkButton
          to="/transmission-data/add/"
          className={classes.emptyTableLink}
        >
          <AddIcon className={classes.emptyTableButton} />
          Add New Transmission
        </EatonLinkButton>
      </div>
    );
  };

  const isSelectedRow = (id: number): boolean => id === selectedRow;

  return (
    <Paper square={false} elevation={3} className={classes.paperBody}>
      {
        <EatonAlertDialog
          open={openDialog}
          onClose={() => setOpenDialog(false)}
          onAgree={onAgreeDialog}
          agreeButtonText="Continue"
          alertTitle="Confirm Delete"
          alertContent="Are you sure you want to permanently delete this Transmission family?"
        />
      }
      {props.loading && <CircularProgress className={classes.progressBar} />}
      {!props.loading &&
        props.transmissions &&
        props.transmissions.length < 1 &&
        !props.loading &&
        getEmptyTable()}
      {!props.loading && props.transmissions && props.transmissions.length > 0 && (
        <Table
          style={{ tableLayout: 'auto' }}
          className="table table-striped"
          aria-labelledby="tabelLabel"
          size="medium"
        >
          <TableHead>
            <StyledTableDataRow>
              <StyledTableCell className={classes.tableCellActions}>
                Actions
              </StyledTableCell>
              <StyledTableCell className={classes.tableCellId}>
                Id
              </StyledTableCell>
              <StyledTableCell className={classes.tableCellName}>
                Name
              </StyledTableCell>
            </StyledTableDataRow>
          </TableHead>
          <TableBody>
            {props.transmissions.map((transmission: Transmission) => (
              <StyledTableDataRow
                key={transmission.id}
                className={
                  isSelectedRow(transmission.id)
                    ? classes.tableCellActive
                    : classes.tableCellInactive
                }
                data-testid={`transmission-row-${cssClassName(
                  transmission.name,
                )}`}
                onClick={() => setSelectedRow(transmission.id)}
              >
                <StyledTableCell>
                  <Tooltip title="Edit">
                    <IconButton
                      component={Link}
                      to={`/transmission-data/edit/${transmission.id}`}
                      data-testid={`${cssClassName(transmission.name)}-edit`}
                    >
                      <EditIcon />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="Delete">
                    <IconButton
                      onClick={async () => {
                        setSelectedRow(transmission.id);
                        setOpenDialog(true);
                      }}
                      data-testid={`${cssClassName(transmission.name)}-delete`}
                    >
                      <DeleteIcon />
                    </IconButton>
                  </Tooltip>
                </StyledTableCell>
                <StyledTableCell>{transmission.id}</StyledTableCell>
                <StyledTableCell className={classes.tableCellName}>
                  {transmission.name}
                </StyledTableCell>
              </StyledTableDataRow>
            ))}
          </TableBody>
          <TableFooter>
            <TableRow>
              <StyledTableCell
                colSpan={4}
                align="right"
                className={classes.tableCellInactive}
              >
                {`Results: ${props.transmissions.length}`}
              </StyledTableCell>
            </TableRow>
          </TableFooter>
        </Table>
      )}
    </Paper>
  );
}
