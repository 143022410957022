import { withStyles, Theme, TableCell, TableRow, makeStyles } from '@material-ui/core';
import * as Colors from '@pxblue/colors';

export const StyledTableCell = withStyles((theme: Theme) => ({
  head: {
    backgroundColor: Colors.blue[500],
    color: theme.palette.common.white,
    fontSize: 16,
    fontWeight: 400,
  },
  body: {
    fontSize: 14,
    borderColor: Colors.gray[100],
  },
}))(TableCell);

export const StyledTableDataRow = withStyles((theme: Theme) => ({
  root: {
    '&:hover': {
      backgroundColor: Colors.gray[100],
    },
    // eslint-disable-next-line quote-props
    backgroundColor: theme.palette.common.white,
  },
}))(TableRow);

export const useStyles = makeStyles((theme: Theme) => {
  return {
    paperBody: {
      marginLeft: '20px',
      marginRight: '20px',
    },
    emptyTableBody: {
      marginTop: '50px',
      textAlign: 'center',
      width: '100%',
      textTransform: 'inherit',
    },
    emptyTableLink: {
      textTransform: 'inherit',
      marginTop: '10px',
    },
    emptyTableButton: {
      marginRight: '5px',
    },
    tableCellActions: {
      width: '10%',
      minWidth: '130px',
    },
    tableCellId: {
      width: '5%',
    },
    tableCellName: {
      width: '80%',
      maxWidth: '0',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
    },
    tableCellInactive: {
      backgroundColor: theme.palette.common.white,
    },
    tableCellActive: {
      backgroundColor: Colors.gray[50],
    },
    progressBar: {
      width: '75px',
      height: '75px',
      marginLeft: '50%',
      marginTop: '50px',
      marginBottom: '50px',
    },
  };
});
