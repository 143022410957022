import React, { useEffect } from 'react';
import { Route, Switch, RouteProps, RouteComponentProps } from 'react-router';
import { Layout } from './components/Layout';
import { Home } from './pages/home';
import { TransmissionData } from './pages/transmission/ListTransmission';
import './custom.css';
import { AddTransmissionPage } from './pages/transmission/AddTransmission';
import { EditTransmissionPage } from './pages/transmission/EditTransmission';
import {
  MuiThemeProvider,
  createMuiTheme,
  CssBaseline,
} from '@material-ui/core';
import { Unauthorized } from './components/unauthorized';
import { SurveyPage } from './pages/survey/Survey';
import { DemoPage } from './pages/demo/Demo';
import { TSPTheme } from './styles/theme';
import { TempPage } from './pages/temp/Temp';
import { AppChrome } from './components/app-chrome';
import { ErrorPage } from './pages/error/Error';
import { Help } from './pages/help';
import { RegisterPage } from './pages/register';
import { ThemeOptions } from '@material-ui/core/styles/createMuiTheme';
import { LoginPage } from './pages/login';
import { PrivateRoute, useAuthState } from './components/reusable-components';
import {
  AccountCreatedPage,
  AccountUpdatedPage,
} from './pages/account-created/AccountCreated';
import { ResetPasswordPage } from './pages/reset-password/ResetPassword';
import { ForgotPasswordPage } from './pages/forgot-password/ForgotPassword';
import { SavedSpecPage } from './pages/saved-spec';
import { MySpecsPage } from './pages/my-specs/MySpecs';
import ReactGA, { FieldsObject } from 'react-ga';
import IdleTimer from 'react-idle-timer';
import AuthContextProvider, {
  AuthStatus,
  AuthDispatchContext,
  AuthStateContext,
} from './components/reusable-components/components/AuthContext';
import Cookie from 'js-cookie';
import { InactivePage } from './pages/inactive/Inactive';
import { NotFoundPage } from './pages/not-found/NotFound';

ReactGA.initialize(process.env.REACT_APP_GA_ID || '', {
  testMode: process.env.NODE_ENV === 'test',
});

export const withTracker = <P extends RouteComponentProps>(
  WrappedComponent: React.ComponentType<P>,
  options: FieldsObject = {}
) => {
  const trackPage = (location: RouteComponentProps['location']) => {
    if (location.pathname.match(/\/spec\/[0-9]/)) {
      ReactGA.pageview('/spec/individual');
    } else {
      const path = `${location.pathname}${location.search || ''}${
        location.hash || ''
      }`;
      ReactGA.set({ page: path, ...options });
      ReactGA.pageview(path);
    }
  };

  return (props: P) => {
    useEffect(() => {
      trackPage(props.location);
    }, [props.location]);

    return <WrappedComponent {...props} />;
  };
};

export default class App extends React.Component {
  idleTimer: IdleTimer | null;
  onIdle: (logout: () => void) => void | undefined;

  _onIdle(logout: () => void) {
    if (this.idleTimer) {
      logout();
    }
  }

  constructor(props: React.ComponentProps<any>) {
    super(props);
    this.idleTimer = null;
    this.onIdle = this._onIdle.bind(this);
  }

  render() {
    return (
      <AuthStateContext.Consumer>
        {(stateValue) => (
          <AuthDispatchContext.Consumer>
            {(dispatchValue) => (
              <Layout>
                <MuiThemeProvider
                  theme={createMuiTheme(TSPTheme as ThemeOptions)}
                >
                  {dispatchValue &&
                    stateValue &&
                    stateValue.authenticated === AuthStatus.Authenticated && (
                      <IdleTimer
                        ref={(ref) => {
                          this.idleTimer = ref;
                        }}
                        element={document}
                        onIdle={(e) => this.onIdle(dispatchValue.timeout)}
                        debounce={250}
                        timeout={1000 * 60 * 60 * 1} // 1 hour
                      />
                    )}
                  <Switch>
                    <Route path='/login' component={LoginPage} />
                    <AppChrome>
                      <CssBaseline />
                      <Switch>
                        <Route exact path={'/'} component={withTracker(Home)} />
                        <PrivateRoute
                          exact
                          loginPath='/login'
                          allowedRoles={['TransmissionAdmin']}
                          path={'/transmission-data'}
                          component={TransmissionData}
                        />
                        <PrivateRoute
                          exact
                          loginPath='/login'
                          path={'/created'}
                          component={withTracker(AccountCreatedPage)}
                        />
                        <Route
                          exact
                          path={'/updated'}
                          component={withTracker(AccountUpdatedPage)}
                        />
                        <PrivateRoute
                          allowedRoles={['TransmissionAdmin']}
                          loginPath='/login'
                          path={'/transmission-data/add'}
                        >
                          <AddTransmissionPage />
                        </PrivateRoute>
                        <PrivateRoute
                          allowedRoles={['TransmissionAdmin']}
                          loginPath='/login'
                          path={'/transmission-data/edit/:id'}
                          component={EditTransmissionPage}
                        />
                        <PrivateRoute
                          exact
                          allowedRoles={[
                            'TransmissionAdmin',
                            'TransmissionUser',
                          ]}
                          loginPath='/login'
                          path={'/spec'}
                          component={MySpecsPage}
                        />
                        <PrivateRoute
                          allowedRoles={[
                            'TransmissionAdmin',
                            'TransmissionUser',
                          ]}
                          loginPath='/login'
                          path={'/spec/:id'}
                          component={withTracker(SavedSpecPage)}
                        />
                        <Route
                          path='/unauthorized'
                          component={withTracker(Unauthorized)}
                        />
                        <Route
                          path='/register'
                          component={withTracker(RegisterPage)}
                        />
                        <Route
                          path='/reset-password'
                          component={withTracker(ResetPasswordPage)}
                        />
                        <Route
                          path='/forgot-password'
                          component={withTracker(ForgotPasswordPage)}
                        />
                        <PrivateRoute
                          loginPath='/login'
                          path={'/demo'}
                          component={DemoPage}
                        />
                        <Route
                          path={'/survey'}
                          component={withTracker(SurveyPage)}
                        />
                        <Route
                          path={'/privacy-policy'}
                          component={withTracker((props: RouteProps) => (
                            <TempPage
                              {...props}
                              text={
                                'This is a temporary page for Privacy Policy. Please change once copy is provided.'
                              }
                            />
                          ))}
                        />
                        <Route
                          path={'/terms-and-conditions'}
                          component={withTracker((props: RouteProps) => (
                            <TempPage
                              {...props}
                              text={
                                'This is a temporary page for Terms & Conditions. Please change once copy is provided.'
                              }
                            />
                          ))}
                        />
                        <Route
                          path={'/contact-us'}
                          component={withTracker((props: RouteProps) => (
                            <TempPage
                              {...props}
                              text={
                                'This is a temporary page for Contact Us. Please change once copy is provided.'
                              }
                            />
                          ))}
                        />
                        <Route
                          path={'/error'}
                          component={withTracker(ErrorPage)}
                        />
                        <Route
                          path={'/inactive'}
                          component={withTracker(InactivePage)}
                        />
                        <Route path={'/help'} component={withTracker(Help)} />
                        <Route component={withTracker(NotFoundPage)} />
                      </Switch>
                    </AppChrome>
                  </Switch>
                </MuiThemeProvider>
              </Layout>
            )}
          </AuthDispatchContext.Consumer>
        )}
      </AuthStateContext.Consumer>
    );
  }
}
