export interface Transmission {
  id: number;
  name: string;
  description: string;
  learnMoreUrl: string;
  supportedApplications: SupportedApplications;
  maxSupportedGcw: number | null;
  supportedTerrain: SupportedTerrain;
  supportedManeuvers: SupportedManeuvers;
  supportedRoadTypes: SupportedRoadTypes;
  maxSupportedCruiseSpeed: number;
  maxSupportedMaxSpeed: number;
  supportedPto: SupportedPto;
  supportedEngineMakes: SupportedEngineMakes;
  maxSupportedEngineHp: number;
  performanceGoalPoints: PerformanceGoalPoints;
  axleRatioThresholds: AxleRatioThresholds;
  modelTorques: ModelTorque[];
  image: { content: string } | null;
}

export enum SupportValue { NotSupported = 'NotSupported', Supported = 'Supported', Recommended = 'Recommended' }

export interface AxleRatioThresholds {
  id?: number;
  defaultBottom: number;
  defaultTop: number;
  mediumBottom: number;
  mediumTop: number;
  highBottom: number;
  highTop: number;
  extremeBottom: number;
  extremeTop: number;
}

export interface ModelTorque {
  name: string;
  maxTorque: number;
  id?: number;
}

export interface PerformanceGoalPoints {
  acceleration: number;
  fuelEconomy: number;
  lowSpeedManeuvers: number;
  price: number;
  tripTime: number;
  id?: number;
}

export interface SupportedApplications {
  linehaul: SupportValue;
  agriculture: SupportValue;
  construction: SupportValue;
  doorToDoorRefuse: SupportValue;
  fire: SupportValue;
  heavyHaul: SupportValue;
  logging: SupportValue;
  mixer: SupportValue;
  oilField: SupportValue;
  pickUpAndDelivery: SupportValue;
  recreational: SupportValue;
  refuse: SupportValue;
  rescue: SupportValue;
  transit: SupportValue;
  id?: number;
}

export interface SupportedEngineMakes {
  cumminsDiesel: SupportValue;
  cumminsNaturalGas: SupportValue;
  detroit: SupportValue;
  international: SupportValue;
  other: SupportValue;
  paccar: SupportValue;
  id?: number;
}

export interface SupportedManeuvers {
  repeatedLaunches: SupportValue;
  slowCreeping: SupportValue;
  urbanStopAndGo: SupportValue;
  mud: SupportValue;
  fastInReverse: SupportValue;
  id?: number;
}

export interface SupportedPto {
  noPto: SupportValue;
  onePto: SupportValue;
  twoPto: SupportValue;
  threeOrMorePto: SupportValue;
  id?: number;
}

export interface SupportedRoadTypes {
  paved: SupportValue;
  maintainedGravel: SupportValue;
  offRoad: SupportValue;
  id?: number;
}

export interface SupportedTerrain {
  flat: SupportValue;
  hillyRolling: SupportValue;
  occasionalMountain: SupportValue;
  frequentMountain: SupportValue;
  steepGrade: SupportValue;
  id?: number;
}

export enum TransmissionConstants {
  MIN_WEIGHT = 33000,
  MAX_WEIGHT = 250000,
  MIN_SPEED = 45,
  MAX_SPEED = 80,
  MIN_HP = 330,
  MAX_HP = 625,
  MIN_TORQUE = 1000,
  MAX_TORQUE = 2250,
}

export const ACCEPTABLE_TRANSMISSION: Transmission = {
  image: null,
  supportedApplications: {
    linehaul: SupportValue.Supported,
    agriculture: SupportValue.NotSupported,
    construction: SupportValue.NotSupported,
    doorToDoorRefuse: SupportValue.NotSupported,
    fire: SupportValue.Recommended,
    heavyHaul: SupportValue.Recommended,
    logging: SupportValue.NotSupported,
    mixer: SupportValue.NotSupported,
    oilField: SupportValue.NotSupported,
    pickUpAndDelivery: SupportValue.NotSupported,
    recreational: SupportValue.NotSupported,
    refuse: SupportValue.NotSupported,
    rescue: SupportValue.NotSupported,
    transit: SupportValue.NotSupported,
    id: 1,
  },
  maxSupportedGcw: 127000,
  supportedTerrain: {
    flat: SupportValue.Supported,
    hillyRolling: SupportValue.NotSupported,
    occasionalMountain: SupportValue.NotSupported,
    frequentMountain: SupportValue.NotSupported,
    steepGrade: SupportValue.NotSupported,
    id: 1,
  },
  supportedManeuvers: {
    repeatedLaunches: SupportValue.NotSupported,
    slowCreeping: SupportValue.NotSupported,
    urbanStopAndGo: SupportValue.NotSupported,
    mud: SupportValue.NotSupported,
    fastInReverse: SupportValue.NotSupported,
    id: 1,
  },
  supportedRoadTypes: {
    paved: SupportValue.NotSupported,
    maintainedGravel: SupportValue.NotSupported,
    offRoad: SupportValue.NotSupported,
    id: 1,
  },
  maxSupportedCruiseSpeed: 70,
  maxSupportedMaxSpeed: 45,
  supportedPto: {
    noPto: SupportValue.NotSupported,
    onePto: SupportValue.NotSupported,
    twoPto: SupportValue.NotSupported,
    threeOrMorePto: SupportValue.NotSupported,
    id: 1,
  },
  supportedEngineMakes: {
    cumminsDiesel: SupportValue.NotSupported,
    cumminsNaturalGas: SupportValue.NotSupported,
    detroit: SupportValue.NotSupported,
    international: SupportValue.NotSupported,
    other: SupportValue.NotSupported,
    paccar: SupportValue.NotSupported,
    id: 1,
  },
  maxSupportedEngineHp: 485,
  performanceGoalPoints: {
    acceleration: 0,
    fuelEconomy: 1,
    lowSpeedManeuvers: 4,
    price: 0,
    tripTime: 0,
    id: 1,
  },
  axleRatioThresholds: {
    id: 1,
    defaultBottom: 2,
    defaultTop: 2,
    mediumBottom: 2,
    mediumTop: 2,
    highBottom: 2,
    highTop: 2,
    extremeBottom: 2,
    extremeTop: 2,
  },
  modelTorques: [
    {
      maxTorque: 2200,
      name: 'beep',
      id: 60,
    },
    {
      maxTorque: 2200,
      name: 'boop',
      id: 61,
    },
  ],
  name: 'hey',
  description: 'pal',
  id: 43,
  learnMoreUrl: 'http://learn.more.com',
};

/**
 * This object exists for us to grab keys from and to populate the default transmission values.
 */
export const DEFAULT_TRANSMISSION: Omit<Transmission, 'id'> = {
  name: '',
  learnMoreUrl: '',
  description: '',
  image: null,
  supportedApplications: {
    agriculture: SupportValue.NotSupported,
    construction: SupportValue.NotSupported,
    doorToDoorRefuse: SupportValue.NotSupported,
    fire: SupportValue.NotSupported,
    heavyHaul: SupportValue.NotSupported,
    linehaul: SupportValue.NotSupported,
    logging: SupportValue.NotSupported,
    mixer: SupportValue.NotSupported,
    oilField: SupportValue.NotSupported,
    pickUpAndDelivery: SupportValue.NotSupported,
    recreational: SupportValue.NotSupported,
    refuse: SupportValue.NotSupported,
    rescue: SupportValue.NotSupported,
    transit: SupportValue.NotSupported,
  },
  maxSupportedGcw: TransmissionConstants.MIN_WEIGHT,
  supportedTerrain: {
    flat: SupportValue.NotSupported,
    hillyRolling: SupportValue.NotSupported,
    occasionalMountain: SupportValue.NotSupported,
    frequentMountain: SupportValue.NotSupported,
    steepGrade: SupportValue.NotSupported,
  },
  supportedManeuvers: {
    repeatedLaunches: SupportValue.NotSupported,
    slowCreeping: SupportValue.NotSupported,
    urbanStopAndGo: SupportValue.NotSupported,
    mud: SupportValue.NotSupported,
    fastInReverse: SupportValue.NotSupported,
  },
  supportedRoadTypes: {
    paved: SupportValue.NotSupported,
    maintainedGravel: SupportValue.NotSupported,
    offRoad: SupportValue.NotSupported,
  },
  maxSupportedCruiseSpeed: TransmissionConstants.MIN_SPEED,
  maxSupportedMaxSpeed: TransmissionConstants.MIN_SPEED,
  supportedPto: {
    noPto: SupportValue.NotSupported,
    onePto: SupportValue.NotSupported,
    twoPto: SupportValue.NotSupported,
    threeOrMorePto: SupportValue.NotSupported,
  },
  supportedEngineMakes: {
    cumminsDiesel: SupportValue.NotSupported,
    cumminsNaturalGas: SupportValue.NotSupported,
    detroit: SupportValue.NotSupported,
    international: SupportValue.NotSupported,
    other: SupportValue.NotSupported,
    paccar: SupportValue.NotSupported,
  },
  maxSupportedEngineHp: TransmissionConstants.MIN_HP,
  performanceGoalPoints: {
    acceleration: 0,
    fuelEconomy: 0,
    lowSpeedManeuvers: 0,
    price: 0,
    tripTime: 0,
  },
  axleRatioThresholds: {
    defaultBottom: 2,
    defaultTop: 2,
    mediumBottom: 2,
    mediumTop: 2,
    highBottom: 2,
    highTop: 2,
    extremeBottom: 2,
    extremeTop: 2,
  },
  modelTorques: [],
};
