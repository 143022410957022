import React, { useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import Typography from '@material-ui/core/Typography';
import {
  CircularProgress,
  FormControl,
  InputLabel,
  Input,
  InputAdornment,
  IconButton,
  Link,
} from '@material-ui/core';
import useStateWhileMounted from '../reusable-components/hooks/use-state-while-mounted';
import {
  LoginForm,
  LoginFormResult,
} from '../reusable-components/hooks/use-login';
import { useStyles } from './styles';
import { EatonButton } from '../button';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import { EmailField, validateEmail } from '../email-field';

function defaultValidPassword(password: string | null | undefined): boolean {
  return !!(password && typeof password === 'string' && password.length > 0);
}

type Props = {
  submitForm: (form: LoginForm) => Promise<LoginFormResult>;

  // TODO: These could return a string that can be displayed to inform the user what's not valid
  validEmail?: (emailAddress: string | null | undefined) => boolean;
  validPassword?: (password: string | null | undefined) => boolean;
};

const UserLogin: React.FC<Props> = (props: Props) => {
  const classes = useStyles(props); // TODO: Does this allow sufficient customization of the look/feel of the login component?

  const [email, setEmail] = useState('');

  const [showPassword, setShowPassword] = useState(false);
  const [password, setPassword] = useState('');
  const [errorMessages, setErrorMessages] = useState<string[]>([]);
  const [submitting, setSubmitting] = useStateWhileMounted(false); // Since we might get redirected away upon successful login, use this helper to avoid React warning about setting state after unmount

  const isValidEmail = (props.validEmail || validateEmail)(email);
  const isValidPassword = (props.validPassword || defaultValidPassword)(
    password,
  );
  const canLogin = !submitting && isValidEmail && isValidPassword;
  const fieldsDisabled = submitting;

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  return (
    <div className={classes.loginContainer}>
      <div className={classes.loginPage}>
        <div className={classes.loginContentContainer}>
          <div className={classes.loginFormContainer}>
            <img src="../../../../../logo.png" className={classes.loginLogo} />
            <Typography variant="h2" className={classes.loginTitle}>
              Transmission Spec Tool
            </Typography>
            <form
              onSubmit={async (evt): Promise<void> => {
                try {
                  evt.preventDefault();
                  setSubmitting(true);
                  const result = await props.submitForm({
                    email,
                    password,
                  });
                  if (!result.success) {
                    setErrorMessages(result.messages);
                  }
                } finally {
                  setSubmitting(false);
                }
              }}
            >
              <EmailField
                email={email}
                setEmail={setEmail}
                validEmail={props.validEmail}
                disable={fieldsDisabled}
                className={`${classes.loginFormFields} ${classes.loginFieldMargin}`}
              />
              <div className={classes.loginFieldMargin}>
                <FormControl className={classes.loginFormFields}>
                  <InputLabel htmlFor="password">Password</InputLabel>
                  <Input
                    id="password"
                    data-testid="password"
                    type={showPassword ? 'text' : 'password'}
                    inputProps={{ maxLength: 80 }}
                    disabled={fieldsDisabled}
                    value={password}
                    onChange={(evt): void => setPassword(evt.target.value)}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                          onMouseDown={(event) => {
                            event.preventDefault();
                          }}
                        >
                          {showPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      </InputAdornment>
                    }
                  />
                </FormControl>
                <Typography className={classes.loginForgot}>
                  <Link
                    component={RouterLink}
                    to="/forgot-password"
                    data-testid="forgotpassword"
                  >
                    Forgot password?
                  </Link>
                </Typography>
              </div>
              <EatonButton
                type="submit"
                disabled={!canLogin}
                color="primary"
                data-testid="login"
                className={`${classes.loginFormFields} ${classes.loginSubmitButton}`}
              >
                Sign In
                {submitting && (
                  <CircularProgress
                    size={24}
                    style={{
                      position: 'absolute',
                      top: '50%',
                      left: '50%',
                      marginTop: -12,
                      marginLeft: -12,
                    }}
                  />
                )}
              </EatonButton>
              {errorMessages && (
                <div>
                  <Typography className={classes.errorText}>
                    {errorMessages.join(',')}
                  </Typography>
                </div>
              )}
            </form>
            <div>
              <Typography className={classes.loginCreateAccount}>
                {`Don't have an account? `}
                <Link component={RouterLink} to="/register">
                  Sign Up
                </Link>
              </Typography>
            </div>
            <div className={classes.backtoHome}>
            <Typography className={classes.loginCreateAccount}>
              <Link component={RouterLink} to="/">
                 Back to Home
                </Link>
              </Typography>
            </div>
          </div>
        </div>
      </div>
    </div>
    //   container
    //   alignItems="center"
    //   justify="center"
    //   className={'branding-panel'}
    // >
    //   <Paper className={classes.card}>
    //     {props.logo}

    //       <Grid container direction="column" justify="space-between">
    //         <Grid
    //           container
    //           direction="row"
    //           alignItems="center"
    //           justify="space-between"
    //           className={classes.buttonRow}
    //         >
    //           <FormControlLabel
    //             control={
    //               <Checkbox
    //                 disabled={fieldsDisabled}
    //                 checked={rememberMe}
    //                 onChange={(evt): void => setRememberMe(evt.target.checked)}
    //               />
    //             }
    //             label="Remember me"
    //           />
    //         </Grid>
    //         <Grid container className={classes.flexrow}>
    //           <Typography variant="body2">
    //             <Link
    //               to="/register"
    //               className={`${classes.link} ${classes.first}`}
    //             >
    //               Sign Up
    //             </Link>
    //           </Typography>
    //           <Typography variant="body2">
    //             <Link to="/forgot-password" className={classes.link}>
    //               Forgot Password?
    //             </Link>
    //           </Typography>
    //         </Grid>
    //       </Grid>
    //     </form>
    //     {props.cyberSecurity}
    //   </Paper>
    // </Grid>
  );
};

export default UserLogin;
