import React, { useState } from 'react';
import { useAuthDispatch } from '../reusable-components/components/AuthContext';
import { EatonButton } from '../../components/button';
import { EatonSnackBar, SnackBarType } from '../snack-bar';

type UserData = {
  firstName: string;
  lastName: string;
  email: string;
  organizationType: string;
  organizationName: string;
  vocationCheckList: string;
  countryList: string;
  numberSavedSurveys: number;
}

export const UserDataDump = (props: {}) => {
  const { authenticatedRequest } = useAuthDispatch();
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<boolean>(false);

  const createDataDump = async () => {
    setLoading(true);
    if (error) setError(false);
    const result = await authenticatedRequest({
      url: 'api/security/allusers',
      method: 'GET',
    });
    if (result.status < 300) {
      const data = makeCSV(result.body as UserData[]);
      const hiddenElement = document.createElement('a');
      hiddenElement.href = 'data:text/csv;charset=utf-8,' + encodeURI(data);
      hiddenElement.target = '_blank';
      hiddenElement.download = 'transmission_spec_users.csv';
      hiddenElement.click();
    } else {
      setError(true);
    }
    setLoading(false);
  };

  const makeCSV = (data: UserData[]): string => {
    let csv = 'Email,First Name,Last Name,Organization Type,Organization Name,Application Self-Description,Country List,Number Saved Surveys\n';
    data.forEach((row) => {
      csv += `${row.email},`;
      csv += `${row.firstName},`;
      csv += `${row.lastName},`;
      csv += `${row.organizationType},`;
      csv += `${row.organizationName},`;
      csv += `${row.vocationCheckList},`;
      csv += `${row.countryList},`;
      csv += `${row.numberSavedSurveys}\n`;
    });
    return csv;
  };
  return (
    <>
      <EatonButton onClick={createDataDump} loadingSpinner={loading} disabled={loading} data-testid="user-data-dump-button">
        Get User Data
      </EatonButton>
      {
        error &&
          <EatonSnackBar
            type={SnackBarType.Error}
            message={'An error occured while trying to get the user data.'}
            onClose={() => setError(false)}
            showSnack={error}
          />
      }
    </>
  );
};
